<template>
    <div>
        <!-- Filters -->
        <list-filters :status-filter.sync="statusFilter"
                      :name-filter.sync="nameFilter"
                      :code-filter.sync="codeFilter"
                      :status-options="statusOptions" />

        <!-- Table Container Card -->
        <b-card no-body class="mb-0">
            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->
                    <b-col cols="12"
                           md="6"
                           class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>{{$t('recordcount')}}</label>
                        <v-select v-model="perPage"
                                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                  :options="perPageOptions"
                                  :clearable="false"
                                  class="per-page-selector d-inline-block mx-50" />

                    </b-col>

                    <!-- Search -->
                    <b-col cols="12"
                           md="6">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-form-input v-model="searchQuery" class="d-inline-block mr-1" :placeholder="$t('Search')" />
                            <b-button variant="primary" @click="goAdd">
                                <span class="text-nowrap">{{$t('addnewitem')}}</span>
                            </b-button>
                        </div>
                    </b-col>
                </b-row>

            </div>

            <b-table ref="refListTable"
                     class="position-relative"
                     :items="fetchAllData"
                     responsive
                     :fields="tableColumns"
                     primary-key="id"
                     :sort-by.sync="sortBy"
                     show-empty
                     empty-text="No matching records found"
                     :sort-desc.sync="isSortDirDesc">

                <!-- Column: Name -->
                <template #cell(name)="data">
                    <b-media vertical-align="center">
                        <template #aside>
                            <b-link v-b-modal.modal-1 @click="selectedId=data.item.id">
                                <b-avatar class="mr-1" size="32" :src="avatarText(data.item.name)" :text="avatarText(data.item.name)" />
                                <span class="font-weight-bold mr-1 text-nowrap">{{ data.item.name }}</span>
                            </b-link>
                        </template>
                    </b-media>
                </template>

                <!-- Column: Code -->
                <template #cell(code)="data">
                    <b-badge pill variant="light-primary" class="text-capitalize">
                        {{ data.item.code }}
                    </b-badge>
                </template>

                <!-- Column: Status -->
                <template #cell(status)="data">
                    <b-badge pill :variant="`light-${resolveStatusVariant(data.item.status)}`" class="text-capitalize">
                        {{ $t(`status.${data.item.status}`) }}
                    </b-badge>
                </template>

                <!-- Column: Actions -->
                <template #cell(actions)="data">
                    <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
                        <template #button-content>
                            <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                        </template>
                        <b-dropdown-item @click="goView(data.item.id)">
                            <feather-icon icon="FileTextIcon" class="blue-button" />
                            <span class="align-middle ml-50">{{$t('details')}}</span>
                        </b-dropdown-item>

                        <b-dropdown-item @click="goEdit(data.item.id)">
                            <feather-icon icon="EditIcon" class="green-button" />
                            <span class="align-middle ml-50">{{$t('edit')}}</span>
                        </b-dropdown-item>

                        <b-dropdown-item @click="goDelete(data.item.id)">
                            <feather-icon icon="TrashIcon" class="red-button" />
                            <span class="align-middle ml-50">{{$t('delete')}}</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </template>

            </b-table>
            <div class="mx-2 mb-2">
                <b-row>

                    <b-col cols="12"
                           sm="6"
                           class="d-flex align-items-center justify-content-center justify-content-sm-start">
                        <span class="text-muted">
                            {{$t('showingrecord')}} :  {{ dataMeta.to }} / {{ dataMeta.from }}
                        </span>
                    </b-col>
                    <!-- Pagination -->
                    <b-col cols="12"
                           sm="6"
                           class="d-flex align-items-center justify-content-center justify-content-sm-end">

                        <b-pagination v-model="currentPage"
                                      :total-rows="total"
                                      :per-page="perPage"
                                      first-number
                                      last-number
                                      class="mb-0 mt-1 mt-sm-0"
                                      prev-class="prev-item"
                                      next-class="next-item">
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>

                    </b-col>

                </b-row>
            </div>
        </b-card>

        <!-- basic modal -->
        <b-modal id="modal-1" ok-only :ok-title="$t('close')">
            <view-form :id="selectedId" />
        </b-modal>
    </div>
</template>

<script>
    import {
        BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
        BBadge, BDropdown, BDropdownItem, BPagination, BModal,
    } from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import store from '@/store'
    import { onUnmounted, ref } from '@vue/composition-api'
    import { avatarText } from '@core/utils/filter'
    import ListFilters from './ListFilters.vue'
    import ViewForm from '../view/View.vue';
    import useList from './useList'
    import storeModule, { APP_STORE_MODULE_NAME } from '../storeModule';

    export default {
        components: {
            ListFilters,
            ViewForm,
            BCard,
            BRow,
            BCol,
            BFormInput,
            BButton,
            BTable,
            BMedia,
            BAvatar,
            BLink,
            BBadge,
            BDropdown,
            BDropdownItem,
            BPagination,
            vSelect,
            BModal,
        },
        data() {
            return {
                selectedId: null,
            }
        },
        mounted() {
        },
        methods: {
            goAdd() {
                this.$router.push({ name: `${APP_STORE_MODULE_NAME}-add` })
            },
            goEdit(id) {
                this.$router.push({ name: `${APP_STORE_MODULE_NAME}-edit`, params: { id } })
            },
            goView(id) {
                this.$router.push({ name: `${APP_STORE_MODULE_NAME}-view`, params: { id } })
            },
            goDelete(id) {
                store.dispatch(`${APP_STORE_MODULE_NAME}/suspend`, { id })
                    .then(() => {
                        this.$bvToast.toast(`${this.$t("processesissuccesful")} `, {
                            title: `${this.$t("success")} `,
                            variant: "success",
                            solid: true,
                        });
                        this.refetchData();
                    })
                    .catch(error => {
                        const h = this.$createElement
                        let vNodesMsg = [];
                        const errors = error.response?.data.errors;
                        if (errors) {
                            Object.keys(errors).forEach(key => {
                                vNodesMsg.push(h('strong', [
                                    h('div', { class: 'mr-2' }, `${key} : ${errors[key][0]}`),
                                ]));
                            });
                        } else {
                            vNodesMsg.push(h('div', [
                                h('strong', { class: 'mr-2' }, error),
                            ]));
                        }
                        this.$bvToast.toast([vNodesMsg], {
                            title: `${this.$t("error")} `,
                            variant: "danger",
                            solid: true,
                        });
                    })
            },
        },
        setup() {

            // Register module
            if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, storeModule)

            // UnRegister on leave
            onUnmounted(() => {
                if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
            })

            const {
                fetchAllData,
                refetchData,
                tableColumns,
                perPage,
                currentPage,
                total,
                dataMeta,
                perPageOptions,
                searchQuery,
                sortBy,
                isSortDirDesc,
                refListTable,

                // UI
                resolveStatusVariant,
                statusOptions,

                // Extra Filters
                statusFilter,
                nameFilter,
                codeFilter,
            } = useList()

            return {

                // Sidebar
                fetchAllData,
                tableColumns,
                perPage,
                currentPage,
                total,
                dataMeta,
                perPageOptions,
                searchQuery,
                sortBy,
                isSortDirDesc,
                refListTable,
                refetchData,
                APP_STORE_MODULE_NAME,

                // Filter
                avatarText,

                // UI
                resolveStatusVariant,
                statusOptions,

                // Extra Filters
                statusFilter,
                nameFilter,
                codeFilter,
            }
        },
    }
</script>

<style lang="scss" scoped>
    .per-page-selector {
        width: 90px;
    }

    .red-button {
        stroke: red;
    }

    .green-button {
        stroke: green;
    }

    .blue-button {
        stroke: blue;
    }
</style>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>
<style>
    button.swal2-confirm.swal2-styled {
        margin: 15px;
    }
</style>
